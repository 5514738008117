import { Component, Input } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { UtilsService } from '../../../../services/utils.service';
import { Pack } from '../../../../shared/interface/pack';
import { AuctionService } from 'src/app/services/auction.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-normal-pack',
  templateUrl: './normal-pack.component.html',
  styleUrls: ['./normal-pack.component.scss'],
})
export class NormalPackComponent {
  @Input() pack: Pack;
  slideOps = {
    slidesPerView: 3,
    initialSlide: 0,
    speed: 100,
    loop: false,
  };
  showQR = false;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  constructor(private utilsService: UtilsService, private toastController: ToastController) {
    
  }

  get types() {    
    const types: string[] = this.pack && this.pack.products ?
      this.pack.products.map(item => `${item.product_type} ${item.product_subtype}`) : [];
      //return types.length > 0 ? types.concat(', ') : '';
      return types;
  }
  

  closeModal() {
    this.utilsService.dismissModal();
  }

  async upComming() {
    const toast = await this.toastController.create({
      message: 'Coming soon!',
      duration: 2000,
    });

    await toast.present();
  }

}
