<app-layout-modal (closed)="closeModal()" [border]="true">
  <ion-grid class="grid-wrapper">
    <ion-row>
      <!-- Parte izquierda -->
      <ion-col size="12" sizeMd="4" class="ion-no-margin">
        <article class="pack-info">
          <section class="pack-info-col">
            <section class="pack-thumbnail">
              <ion-img [src]="pack?.thumbnail?.url !== null ? pack?.thumbnail?.url : 'assets/images/placeholder.svg'">
              </ion-img>
            </section>
            <!-- <section class="details">
              <ion-text color="light">
                <h3 class="ion-text-capitalize">{{ pack?.name | titlecase }}</h3>
              </ion-text>
            </section> -->
          </section>
          <section class="more-details">
            <!-- <p class="mini-paragraph">{{ pack?.shortDescription }}</p> -->
            <div class="more-details-grid">
              <!-- <ion-text color="light">{{'profile_modal.pages.points_win' | transloco}}: {{ pack?.member_points }}</ion-text> -->
              <ion-text color="light" class="lighter-text">{{ pack?.ends | date:'d/MM/y' }}</ion-text>
            </div>
          </section>
        </article>
        
        <article class="pack-products">
          <ion-slides [options]="slideOps" style="display: flex; gap: 1rem">
            <ion-slide *ngFor="let product of pack?.products">
              <section class="pack-slide">
                <ion-img class="slide-img"
                  [src]="product?.thumbnail && product?.thumbnail?.url !== null ? product?.thumbnail?.url : 'assets/images/white-placeholder.svg'">
                </ion-img>
                <ion-text color="light" class="mini-text">{{ product?.name }}</ion-text>
              </section>
            </ion-slide>
          </ion-slides>
        </article>
      </ion-col>
      <!-- Parte derecha -->
      <ion-col size="12" sizeMd="8" class="ion-no-margin">
        <section class="details-wrapper" *ngIf="!showQR">
          <section class="detail-content">
            <article class="pack-details">
              <section class="info">
                <!-- <ion-text color="light" class="ion-text-capitalize">
                  <h3>{{ types }}</h3>
                </ion-text>
                <ion-text color="light">Limited FAN Edition</ion-text>
                <div [innerHtml]="pack?.description"></div> -->
                <h3 class="ion-text-capitalize">{{ pack?.name | titlecase }}</h3>
                <p class="mini-paragraph" style="font-size: 1.75rem;">{{ pack?.shortDescription }}</p>
              </section>
              <section class="contents">
                <ion-text color="light" style="font-size: 1.75rem;">Este pack incluye:</ion-text>
                <ul>
                  <li *ngFor="let product of pack?.products">
                    <p style="font-size: 1.75rem;">{{ product?.description }}</p>
                  </li>
                </ul>               
                <ion-button size="large" fill="solid" class="login-btn" (click)="showQR = true">
                  QR para canjear premio
                </ion-button>
              </section>              
            </article>
            <!-- <article class="actions">
              <ion-button (click)="upComming()" class="hover-to-light" fill="outline" expand="block" size="large">Send as a gift</ion-button> 
              <ion-button (click)="upComming()" class="hover-to-light post-for-sale" fill="outline" expand="block" size="large">Post for sale on Lenny's Marketplace</ion-button> 
            </article> -->
          </section>
        </section>
        <ion-grid *ngIf="showQR" >
          <ion-row>
            <h3 class="titulo-qr">Llevar el siguiente QR + DNI presonal para canjear el premio</h3>
          </ion-row>
          <ion-row>
            <ngx-qrcode
            [elementType]="elementType" 
            value="9a1158154dfa42caddbd0694a4e9bdc8"
            class="qrcode"
            [errorCorrectionLevel]="correctionLevel">
          </ngx-qrcode>
          <p class="valor-qr">9a1158154dfa42caddbd0694a4e9bdc8</p>
          </ion-row>
        </ion-grid>        
      </ion-col>
    </ion-row>
  </ion-grid>
</app-layout-modal>