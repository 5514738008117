export const environment = {
  production: true,
  env: 'staging',
  apiServer: 'https://age37dq0z3.execute-api.us-east-1.amazonaws.com/staging-poc',
  publicKeyMercadopago: 'TEST-700ea8bf-f45e-4e6b-a39c-51f24152c713',
  googleClientId: '27179106000-mmmekj9obqmcplung05thkaj4ba9allg.apps.googleusercontent.com',
  paymentWebSocket: 'wss://1my34kceul.execute-api.us-east-1.amazonaws.com/staging-poc',
  captchaKey: '6Lc2iMknAAAAAIBU268dZE8w1CD08qNzz1FUqGTe',
  captchaKeyV3: '6Lc2iMknAAAAAIBU268dZE8w1CD08qNzz1FUqGTe',
};
